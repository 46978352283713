import React from "react";
import { graphql } from "gatsby";

import componentsMap from "components/components";
import Layout from "components/common/Layout";
import useStoryblok from "utils/hooks/useStoryblok";

export default function Page({ pageContext, location, data }) {
  const { lang, languages, story } = pageContext;
  const { layout } = data;
  const pageStory = useStoryblok(story, location);
  const { content } = pageStory;
  const { _uid, metadata, component, header_theme } = content;
  const parsedLayout = layout ? JSON.parse(layout.content) : {};
  const { header, footer } = parsedLayout;

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }
  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={metadata}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      languages={languages}
      lang={lang}
      siteTheme={header_theme}
    >
      {componentsMap[component] ? (
        React.createElement(componentsMap[component], {
          key: _uid,
          blok: content,
          lang: lang,
          path: location.href,
        })
      ) : (
        <h3>Layout component</h3>
      )}
    </Layout>
  );

  // return <h1>test</h1>;
}

export const pageQuery = graphql`
  query PageQuery {
    layout: storyblokEntry(field_component: { eq: "common_layout" }) {
      content
    }
  }
`;
